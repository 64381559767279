.life-on-mars-section::after {
    background: url("../../assets/images/bg-life-mars.jpg") no-repeat center center/cover;
/*    height: 753px;*/
}

.life-on-mars-block-text {
    position: absolute;
    left: 50px;
    top: 81px;
    width: 35%;
}

.life-on-mars-p-container {
    margin-left: 38px;
}

.oval-life-on-mars {
    padding: 4px;
}

.oval-life-on-mars::after {
    border: solid 4px rgba(255, 255, 255, 0.3);
}

div.starmus-flex-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    div.life-on-mars-block {
        height: 660px;
        background: url("../../assets/images/768/bg-life-mars.jpg") no-repeat center center/cover;
    }
    p.life-on-mars-p {
        font-size: 16px;
    }
    .life-on-mars-block-text {
        left: 22px;
        top: 100px;
        width:65%;
    }
    .life-on-mars-text {
        margin-top: 34px;
    }
}

@media only screen and (max-width: 464px) {
    div.life-on-mars-block {
        height: 560px;
        background: url("../../assets/images/375/bg-life-mars.jpg") no-repeat center center/cover;
    }
    p.life-on-mars-p {
        font-size: 13px;
    }
    .life-on-mars-block-text {
        top: 56px;
        width:90%;
    }
    .life-on-mars-text {
        margin-top: 20px;
    }
    div.starmus-flex-center {
        margin-bottom: 10px;
    }
    .life-on-mars-p-container {
        margin-left: 15px;
    }
}