.mars_video_block {
    position: relative;
    line-height: 0;
    background: #000000;
}

p.mars_video_block_description {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding: 0px 15px;
}

div.mars_video_block_text {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
}

h1.mars_video_h1 {
    text-align: center;
}

@media only screen and (max-width: 464px) {
    p.mars_video_block_description {
        font-size: 16px;
    }
}