.armenia_video_block {
    background: #000000;
}

div.armenia_video_block_text {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
}

div.armenia_video_block_text h1, div.starmus-location-p {
    text-align: center;
}

div.starmus-location-p {
    margin-bottom: 31px;
}

.armenia_poster_block {
    background: #f7f6f9;
    padding: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
    .hotels-armenia-blocks {
        display: block;
        padding: 0px;
    }

    .hotels-armenia-block {
        padding: 50px;
    }
    .armenia_poster_block {
        display: block;
        text-align: center;
    }

    .armenia_poster_block img {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .armenia_video_block {
        background: url("../../assets/images/bg-getting-armena.jpg") no-repeat top center/cover;
        padding: 100px 40px 40px;
    }

    .armenia_video_block .video {
        display: none;
    }

    div.armenia_video_block_text {
        position: static;
    }

}

@media only screen and (max-width: 464px) {
    .armenia_video_block {
        background: url("../../assets/images/375/bg-main-location.jpg") no-repeat top center/cover;
    }
}