.accommodations-block-text {
    background-color: #f7f6f9;
    padding: 80px 57px 208px;
}

h1.accommodations-h4 {
    text-align: center;
}

p.accommodations-p {
    font-size: 16px;
}

p.accommodations-p b {
    padding-right: 6px;
}

.hotels-slider {
    margin-top: -190px;
    width: 100%;
    text-align: center;
}


.hotels-armenia-blocks {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 56px;
}

.hotels-armenia-block {
    flex-grow: 1;
    flex-basis: 0;
    padding: 5px;
}

@media only screen and (max-width: 1024px) {
    .accommodations-block-text {
        padding: 31px 31px 208px;
    }

    .hotels-armenia-blocks {
        display: block;
        padding: 0px;
    }

    .hotels-armenia-block {
        padding: 40px 31px;
    }
}

@media only screen and (max-width: 768px) {
    .hotels-armenia-img {
        width: 100%;
    }

    .hotels-armenia-block {
        padding: 21px;
    }
}
