:root {
    --button-color:#ff8200;
}

.switcher{
    display: flex;
    margin: auto;
    border: 1px solid var(--button-color);
    border-radius: 22px;
    align-items: center;
    width: min-content;
}

.switcher_button{
    font-size: 16px;
    font-weight: 600;
    color: var(--button-color);
    min-width: 98px;
    height: 44px;
    line-height: 44px;
    cursor: pointer;
    white-space: nowrap;
    padding: 0px 15px;
}
.switcher_button.active{
    border-radius: 22px;
    box-shadow: 0 -3px 7px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--button-color);
    color: #ffffff;
}
@media screen and (max-width: 768px) {
    .switcher{
        flex-direction: column;
        border: none;
    }
    .switcher_button{
        width: 100%;
    }
}