@media (min-width: 1025px ) {
    .program_col_left > div:nth-of-type(2n) {
        display: none;
    }
    .program_col_right > div:nth-of-type(2n+1) {
        display: none;
    }
}
@media (max-width: 1024px ) {
    .program_col_left > div{
        padding: 0px 25px;
    }
    .program_col_right > div{
        display: none;
    }
}