.contact_header_section{
    background-image: url("./images/header_bg.png");
}
.contact_section{
    padding-top: 50px;
    padding-bottom: 50px;
}
.contact_info_row{
    padding-left: 65px;
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url("./images/phone.png");
    font-family: Lato;
    line-height: 1.63;
    color: #121037;
    margin-bottom: 32px;
}
.contact_info_row.email{
    background-image: url("./images/email.png");

}
.contact_info_label{
    opacity: 0.6;
}
.contact_section .text_1040{
    margin-bottom: 50px;
}
.contact_links {
    margin-top: 150px;
}
.contact_links a {
    display: block;
    margin-top: 20px;
    text-decoration: underline;
    margin-left: 60px;
}
form.contactform label {
    display: block;
    margin-bottom: 37px;
}
form.contactform input,
form.contactform textarea{
    display: block;
    border-radius: 2px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: 48px;
    width: 100%;
    padding: 0px 15px ;
    margin-top: 5px;
}
form.contactform textarea{
    height: 168px;
    resize: none;
    padding-top: 7px;
    margin-bottom: 24px;
}
form.contactform  .submit_button{
    width: 176px;
    height: 42px;
    background-color: #ff8200;
    color: #ffffff;
    border: none;
    outline: none;
    display: inline-block;
}
form.contactform .form_error{
    color: #ff3603;
}
span.form_success {
    margin-left: 20px;
    display: inline-block;
    color: #0eb923;
}
@media screen and (max-width: 1023px) {
    .contact_links {
        margin-top: 20px;
    }
}