.slider-item {
    position:absolute;
    left:0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity:0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.slider-item > div {
    width: 100%;
    height: 100%;
}
.slider-item img{
    object-fit: cover;
    margin: auto;
    display: block;
}

.contain_slider .slider-item img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.clock_slider .slider-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.opaque {
    opacity:1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=1);
}

.slideshow-block {
    position: relative;
    height: 100%;
    background: white;
    z-index: 0;
}

.arrows {
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.arrow-container {
    position: absolute;
    top: 50%;
    width: 100%;
}

.left-arrow-container {
    padding-left: 56px;
}

.right-arrow-container {
    padding-right: 56px;
}

@media only screen and (max-width: 768px) {
    .left-arrow-container {
        padding-left: 26px;
    }

    .right-arrow-container {
        padding-right: 25px;
    }
}

@media only screen and (max-width: 375px) {
    .left-arrow-container {
        padding-left: 22px;
    }

    .right-arrow-container {
        padding-right: 23px;
    }
}