/*body.overlay{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.overlay .main_content {
    height: calc(100% - 103px);
    overflow: hidden;
    min-height: auto;
}*/
@media screen and  (min-width: 768px){

    :root {
        --code-color: darkred;
        --code-bg-color: #aaaaaa;
        --scroll-bar-color: #c5c5c5;
        --scroll-bar-bg-color: #f6f6f6;
    }

    pre {
        color: var(--code-color);
        background-color: var(--code-bg-color);
    }


    ::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

    * {
        scrollbar-width: 10px;
        scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    *::-webkit-scrollbar-track {
        background: var(--scroll-bar-bg-color);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--scroll-bar-color);
        border-radius: 20px;
        border: 3px solid var(--scroll-bar-bg-color);
    }
}

.am_popup *{
    box-sizing: border-box;
}
.am_popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    background: #000000b0;
    display: none;
    align-items: center;
    justify-content: center;
}
.am_popup.open{
    display: flex;
}
.am_popup_content {
    background: #fff;
    padding: 32px;
    border-radius: 12px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    width: 640px;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.am_popup_close_button {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 26px;
    height: 26px;
    opacity: 0.3;
    cursor: pointer;
    background: url("../assets/images/close.svg") no-repeat center;
}

.am_popup_close_button:hover {
    opacity: 1;
}

.border_box *,
.am_wrapper *{
    box-sizing: border-box;
}
.hidden{
    display: none;
}
.main_content{
    min-height: calc(100vh - 130px);
}
.am_container{
    max-width: 1284px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
}
.back_content{
    font-size: 18px;
}
.page_main{
    font-size: 16px;
    color: #373737;
}
.new_page_container{
    font-size: 18px;
    color: #ffffff;
}
.new_page_container p{
    margin-bottom: 20px;
}
.section_title{
    font-family: Veneer;
    font-size: 48px;
    color: #ffffff;
}
.new_style{
    font-family: "Roboto Regular";
}
.new_style p{
    margin-bottom: 10px;
}
.new_style .section_title{
    font-family: "Roboto Bold";
    font-size: 32px;

}
.new_style .section_title_big{
    font-family: "Roboto Bold";
    font-size: 38px;

}
.new_style_btn1 a{
    width: 313px;
    height: 74px;

    border: 3px solid #AE765C;
    border-radius: 37px;
    font-size: 24px;
    line-height: 68px;
    letter-spacing: 0.285em;
    color: #000000;
    margin-top: 38px;
    display: block;
    text-align: center;
}
.new_style .section_title.puf{
    color: #AE765C;
}
.section_title_big{
    font-size: 58px;
}
.section_title.black{
    color: #000000;
}
.section_sub_title{
    font-family: Montserrat;
    font-size: 20px;
    color: #ffffff;
}
img{
    max-width: 100%;
}
.row{
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
}
.flex_wrap{
    flex-wrap: wrap;
}
.col3,.col4, .col6,.col8{
    flex-shrink:0;
    padding-left: 12px;
    padding-right: 12px;
}
.col4{
    width: 33.333%;
}
.col8{
    width: 66.6666%;
}
.col3{
    width: 25%;
}
.col6{
    width: 50%;
}
.text_bold{
    font-weight: bold;
}
.text_center{
    text-align: center;
}
.ticket_info_modal_title{
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #ae765c;
}
.text_justify{
    text-align: justify;
}
.text_left{
    text-align: left;
}
.text_right{
    text-align: right;
}
.flex{
    display: flex;
}
.flex-column{
    flex-direction: column;
}
.flex_half{
    width: 50%;
    flex-shrink: 0;
}
.align_items_center{
    align-items: center;
}
.height_100{
    height: 100%;
}
.white_bg{
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.21)  0px 0px 6px;
    border-radius: 8px;
}
.more_link{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.44;
    color: #ff8200;
}
.orange_link{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.44;
    color: #ff8200;
}
.festival_slogan_6{
    color: #ff8200 !important;
}
.text_1040{
    max-width: 1040px;
    margin:auto;
}
a{
    color: inherit;
}
.section_with_bg{
    position: relative;
}
.section_with_bg > *{
    z-index: 1;
    position: relative;
}
.img_as_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}
.section_60{
    padding-top: 60px;
    padding-bottom: 60px;
}
.section_light_blue{
    background-color: #f7f6f9;
}
.section_light_blue .figure{
    border: solid 3px #e9e8ed;
}
.two_column_text {
    column-count: 2;
    column-gap: 30px;
}
.am_container .slick-prev, .am_container .slick-next ,
.am_container .slick-prev:hover,
.am_container .slick-prev:focus,
.am_container .slick-next:hover,
.am_container .slick-next:focus{

    background-color: #AE765C ;
    height: 43px;
    width: 43px;
    border-radius: 50%;
    top: -30px;
}

.am_container .slick-next{
    right: 0px;
}
.am_container .slick-prev{
    right: 63px;
    left: auto;
}
.am_container .slick-disabled{

    opacity: 0.62;
}
.am_container .slick-prev:before,.am_container .slick-next:before {
    display: inline-block;
    margin-top: -4px;
    height: 43px;
     font-family: inherit;
    font-size: 20px;
    line-height: 43px;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-list .figure_wrap {
    width: 100%;
    height: 100%;
}
.slick-list .figure{
    padding-bottom: 0;
}
.am_slick .slick-dots li {
    border: 1px solid #0BAAC6;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background-color: #333;
    list-style: none;
}
.am_slick .slick-dots li.slick-active {
    background-color: #0BAAC6;
}
.am_slick .slick-dots li button:before{
    font-size: 0px;
}
.am_container .slick-track{
    display: flex;
}
.am_container .slick-slide{
    height: initial;
}
.am_container .slick-slide > div{
    height: 100%;
}
.s8_section_3 .slick-arrow{
    display: none !important;
}
.header_section{
    height: 353px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 65px;
    background-size: cover;
    background-repeat: no-repeat;
}
.transition{
    transition: all 300ms;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
}
.post_content p{
    margin-bottom: 10px;
}
.show_in_small{
    display: none;
}
.video_bg_section{
    position: relative;
}
.video_bg_button {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000008;
}
.video_bg_iframe{
    width: 100%;
    height: 100%;
}
.video_bg_iframe_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.modal_video_bg_iframe_wrapper,
.modal_video_bg_iframe_wrapper iframe{
    width: 100%;
    height: 100%;
}
.why_armenia_text p{
    margin-bottom: 15px;
}
.form_input {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    padding: 0px 24px;
    font-size: 16px;
    color: #121037;

    border-radius: 3px;
    border: solid 2px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    outline: none;
}
.ticket_form label{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    color: #121037;
}

form.sponsorsForm * {
    box-sizing: border-box;
}
.sponsorsForm_text{
    font-family: 'Roboto Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin-top: 36px;
    margin-bottom: 58px;
}
.sponsorsForm {
    max-width: 100%;
    width: 710px;
    margin: auto;
    text-align: center;
    padding: 73px 64px;
    box-sizing: border-box;
    max-height: calc(100vh - 70px);
    overflow: auto;

}
.sponsorsForm .success_icon_wrap{
    margin: 60px auto 60px;
}
.sponsorsForm .section_title{
    color: #AE765C;
    font-size: 38px;
}
.sponsorsForm input{
    max-width: 100%;
    width: 472px;
    height: 61px;
    display: block;
    margin: 0px auto 30px;
    font-size: 24px;
    mix-blend-mode: normal;
    border: 3px solid #AE765C;
    backdrop-filter: blur(80px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 37px;
    padding: 0px 28px;
    line-height: 55px;
}
.sponsorsForm input.error{
    border-color: #e70e0e;
}
.sponsorsForm input[type="submit"]{
    background-color: #AE765c;
    color: #fff;
}
#tomsarkgh_iframe {
    width: 100%;
    height: calc(95vh - 180px);
}
.home_buy_ticktes {
    background-color: #ee8114;
    display: inline-block;
    padding: 15px 35px;
    border-radius: 35px;
    margin-top: 33px;
    color: #000;
    font-weight: bold;
    font-size: 30px;
}

.color_orange{
    color: #F47A35;
}
.color_white{
    color: #FFFFFF;
}
.color_grey{
    color: #FFFFFF5C;

}
.color_blue{
    color: #0BAAC6;
}
.blue_btn{
    background-color: #0085FF;
    color: #ffffff;
    border-radius: 6px;
    padding: 14px 23px;
    border: none;
    outline: none;
    cursor: pointer;
}
.blue_btn:disabled {
    opacity: 0.8;
    cursor: not-allowed;
}
@media screen and  (max-width: 1024px) {
    .show_in_small{
        display: block;
    }
    .section_title{
        font-size: 32px;
    }
    .page_main{
        font-size: 14px;
    }
    .row{
        flex-wrap: wrap;
    }
    .col4,
    .col6{
        width: 100%;
    }

    .new_style .col6:first-child .section_60 {
        padding-bottom: 0px;
    }
    .header_section{
        background-size: cover;
        background-position: center;
        margin-top: 80px;
        height: 291px;
    }
    .main_content{
        margin-top: 83px;
    }
    .two_column_text{
         column-count: 1;
    }
}
@media screen and  (max-width: 992px) {
    .flex_column_992{
        display: flex;
        flex-direction: column;
    }
}
@media screen and  (max-width: 769px) {
    .flex_half.half_769{
        width: 100%;
    }
}
@media screen and (max-width: 400px){
    .new_style_btn1 a{
        width: 100%;
        letter-spacing: 0.3em;
        font-size: 20px;
    }
}