.missions-section::after {
    background: url("../../assets/images/bg-missions-to-mars.jpg") no-repeat top center/cover;
}

.missions-block-text {
    bottom: 51px;
    left: 55px;
    width: 596px;
    position: absolute;
}

.missions-text p {
    margin-bottom: 20px;
}

.missions-text p span {
    font-size: 30px;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .missions-section {
        background: url("../../assets/images/768/bg-missions-to-mars.jpg") no-repeat top center/cover;
        height: auto;
    }

    .missions-block-text {
        width: 50%;
    }

    div.missions-block {
        height: 660px;
    }

    .missions-text p {
        font-size: 15px;
    }

    .missions-text p span {
        font-size: 25px;
    }
}

@media only screen and (max-width: 375px) {
    .missions-section {
        background: url("../../assets/images/375/bg-missions-to-mars.jpg") no-repeat top center/cover;
    }

    .missions-block-text {
        width: 80%;
    }

    .missions-text p {
        font-size: 14px;
    }

    .missions-text p span {
        font-size: 24px;
    }
}