.p_divider_1{
    height: 65px;
    background: url("../ProgramNew/images/section_3_bg.jpg") #000000 no-repeat;
    background-position: left -300px top;
}
.p_divider_2{
    height: 65px;
    background: url("../ProgramNew/images/section_3_bg.jpg") #000000 no-repeat;
    background-position:  left center ;
}
.inner_page_title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: -40px;
}
.inner_page_logo{
    margin-top: 15px;
    width: 250px;
}
.partner_logos{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.partner_logos_item{
    text-align: center;
    width: 33.3333%;
    padding: 20px;
}
.inner_page_title_type {
    text-align: right;
    font-size: 50px;
}

.partner_section{
    display: flex;
    margin: 70px auto;
}
.partner_section_left{
    width: 70%;
    padding-right: 40px;
}
.partner_section_img{
    width: 30%;
}
.partner_logo{
    max-width: 420px;
    max-height: 120px;
    margin-bottom: 53px;
    width: 100%;
    object-fit: contain;
    object-position: left;
}
.partner_text{
    font-size: 30px;
}
.eset_video_btn{
    width: 100%;
    height: auto;
    margin-top: 20px;
}
@media screen and (max-width: 800px) {
    .inner_page_title {
        flex-direction: column-reverse;
    }
    .partner_section{
        flex-direction: column;
    }
    .partner_section_left{
        width: 100%;
        padding-right: 0px;
    }
    .partner_section_img{
        margin: 30px auto;
        width: 100%;
        text-align: center;
    }
    .inner_page_title_type {
        text-align: left;
    }
    .partner_logos_item{
        width: 50%;
    }
}
@media screen and (max-width: 600px){
    .inner_page_title_type{
        font-size: 40px;
    }
    .partner_text{
        font-size: 25px;
    }
}