.preRegWrap{
    max-width: 450px;
    margin:auto;
}
.preRegTitle{
    font-size: 24px;
    font-family: ApexMk2;
    margin-bottom: 32px;
}
.preRegGif{
    width: 150px;
}
.preRegText{
    font-size: 20px;
    font-family: FormaDJRVariable;
    margin-top: 20px;
}
.preRegUl{
    font-size: 16px;
    text-align: left;
}
.agree_checkbox{

}
.agree_text{
    font-size: 14px;
}
.new_info{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: justify;
}