.mariner9-section::after {
    background: url("../../assets/images/bg-mariner-9.jpg") no-repeat top/cover;
}

.mariner-9 {
    position: absolute;
    top: 200px;
    object-fit: contain;
    transform: rotate(-3deg);
    animation: spin 7s linear infinite;
}

.phobos {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 406px;
}

.mariner9-block-text {
    top: 120px;
    left: 35%;
    width: 596px;
    position: absolute;
}

p.mariner9-p {
    margin: 0px;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
    div.mariner9-block {
        background: url("../../assets/images/768/bg-mariner-9.png") no-repeat top/cover;
        height: 660px;
    }
    .phobos {
        width: 250px;
    }
    p.mariner9-p {
        font-size: 16px;
    }
    .mariner9-block-text {
        top: 20%;
        left: 30%;
        right:15%;
        width: auto;
    }
    .mariner9-text {
        padding-top: 10px;
    }
}

@media only screen and (max-width: 464px) {
    div.mariner9-block {
        background: url("../../assets/images/375/bg-mariner-9.png") no-repeat top/cover;
        height: 756px;
    }
    .phobos {
        width: 150px;
        top: 340px;
    }
    p.mariner9-p {
        font-size: 14px;
    }
    .mariner9-block-text {
        top: 20%;
        left: 6%;
        right:6%;
    }
    .mariner9-text {
        padding-top: 10px;
    }
    .mariner-9 {
        top: 28px;
    }
}

