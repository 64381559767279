.about-starmus-section::after {
    background: url("../../assets/images/bg-about-starmus.jpg") no-repeat center center/cover;
}

.about-starmus-container {
    position: absolute;
    right: 162px;
    top: 56px;
    width: 50%;
    box-sizing: border-box;
}


.about-starmus-logo {
    width: 319px;
}

.starmus-block-text {
    margin-top: 20px;
}

.about-starmus-text-block {
    padding: 15px 25px;
    border-radius: 12px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    margin-bottom: 22px;
}


h1.starmus-about-h1 {
    padding-top: 20px;
}

p.starmus-about-p {
    font-size: 18px;
}

@media only screen and (max-width: 1024px) {
    div.about-starmus-block {
        background: url("../../assets/images/bg-about-starmus.jpg") no-repeat center center/cover;
    }

    .about-starmus-container {
        position: static;
        width: 100%;
        padding: 30px;
        text-align: center;
    }

    .about-starmus-logo-container {
        padding-top: 50px;
    }

    .about-starmus-text-block {
        width: auto;
        height: auto;
        margin-bottom: 10px;
    }

    h1.starmus-about-h1 {
        padding-top: 30px;
    }

    h1.starmus-about-h1 {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 464px) {

    .about-starmus-logo {
        width: 219px;
    }

    .about-starmus-text-block {
        padding: 15px 10px;
    }

    p.starmus-about-p {
        font-size: 14px;
    }
}