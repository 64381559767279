.footer-section {
    background: black;
    padding-bottom: 22px;
}
.footer-section *{
    box-sizing: border-box;
}
div.footer-block {
    height: auto;
}

div.footer-flex {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.footer-component {
    width: 33%;
}

.social-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.social-icon {
    height: 24px;
}

.footer-img {
    width: 121px;
}

.footer-honorary-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
}

div.footer-honorary-p {
    font-size: 22px;
    padding-top: 55px;
    padding-bottom: 45px;
    text-align: center;
    width: 70%;
}

.armenia-logo {
    margin-left: 30px;
}

.armenia-logo img {
    width: 75px;
    opacity: 0.8;
}

p.copy-right-text {
    font-size: 14px;
    text-align: center;
    padding-bottom: 6px;
    padding-top: 6px;

}
.footer_links{
   color: #FFFFFF;
     display: flex;
    gap: 20px;

}
@media only screen and (max-width: 768px) {
    div.footer-honorary-p {
        font-size: 16px;
    }

    .armenia-logo img {
        width: 60px;
    }

    div.footer-flex{
        flex-direction: column;
    }
    .footer-component{
        width: 100%;
        text-align: center;
    }
    p.copy-right-text {
        text-align: center;
        padding-bottom: 20px;
        padding-top: 11px;
    }
    .social-icons{
        justify-content: center;
    }
    .footer_links{

        flex-direction: column;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 464px) {
    div.footer-honorary-p {
        width: 100%;
    }

    .armenia-logo {
        display: none;
    }

}