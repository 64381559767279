.mars-facts-section:after {
    background: url("../../assets/images/bg-facts.jpg") no-repeat top center/cover;
}

.mars-facts-block-text {
    position: absolute;
    top: 100px;
    width: 100%;
}

h1.mars-facts-h1 {
    text-align: center;
}

div.fact-main-text, div.fact-main-passive-text {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 38px;
}

div.fact-main-text {
    top: 250px;
    line-height: 60px;
    width: 100%;
}

div.fact-main-passive-text {
    bottom: 28%;
    color: rgba(256, 256, 256, 0.1);
}

.mars-facts-planets-block {
    display: flex;
    width: 100%;
    position: absolute;
    top: 130px;
}

.mars-facts-planet-block {
    position: relative;
    width: 33.333333333%;
}

.mars-facts-planets {
    height: 604px;
}

.mars-facts-earth {
    background: url("../../assets/images/earth.png") no-repeat left bottom/302px;
}

.mars-facts-mars {
    background: url("../../assets/images/mars.png") no-repeat right bottom/302px;
}

.mars-facts-earth-after, .mars-facts-mars-after {
    content: "";
    display: inline-block;
    width: 750px;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    border: 1px dashed #fff;
    padding: 50px 0px;
    bottom: -56px;
}

.mars-facts-earth-after {
    /*background: url("../../images/mars_facts/oval-left.png") no-repeat top right/100%;*/
    left: -375px;
}

.mars-facts-mars-after {
    /*background: url("../../images/mars_facts/oval-right.png") no-repeat top right/100%;*/
    right: -375px;
}

div.active-fact-text, div.passive-fact-text {
    padding: 0px 10px;
    font-size: 20px;
    position: absolute;
    width: 250px;
}

div.active-fact-text strong, div.passive-fact-text strong {
    font-size: 36px;
}

div.passive-fact-text {
    color: rgba(256, 256, 256, 0.1);
}

div.oval-facts {
    padding: 4px;
    width: 14px;
    height: 14px;
    position: absolute;
    cursor: pointer;
}

.oval-facts::after {
    border: solid 7px rgba(255, 255, 255, 0.3);
}

div.oval-facts-active, div.active-fact-text {
    top: 50%;
}

div.oval-facts-passive, div.passive-fact-text {
    top: 84%;
}

div.oval-facts-passive::after {
    background: rgba(255, 255, 255, 0.1);
}

div.oval-facts-active.oval-mars {
    left: -10px;
}

div.active-fact-text-mars {
    left: -290px;
    text-align: right;
}

div.oval-facts-passive.oval-mars {
    left: 100px;
}

div.passive-fact-text-mars {
    left: -25%;
    text-align: right;
}

div.oval-facts-active.oval-earth {
    right: -10px;
}

div.active-fact-text-earth {
    right: -290px;
    text-align: left;
}

div.oval-facts-passive.oval-earth {
    right: 100px;
}

div.passive-fact-text-earth {
    right: -25%;
    text-align: left;
}

@media only screen and (max-width: 768px), (max-width: 1024px) {
    .mars-facts-section {
        background: url("../../assets/images/768/bg-facts.jpg") no-repeat top center/cover;
        height: 660px;
        display: none;
    }

    .mars-facts-block-text {
        top: 40px;
    }

    .mars-facts-earth {
        background: url("../../assets/images/768/earth.png") no-repeat left bottom/160px;
    }

    .mars-facts-mars {
        background: url("../../assets/images/768/mars.png") no-repeat right bottom/160px;
    }

    div.fact-main-text, div.fact-main-passive-text {
        font-size: 24px;
    }

    div.fact-main-text {
        top: 180px;
    }

    div.active-fact-text strong.fact-strong, div.passive-fact-text strong.fact-strong {
        font-size: 20px;
    }

    .mars-facts-planets {
        height: 380px;
    }

    .mars-facts-earth-after, .mars-facts-mars-after {
        width: 460px;
    }

    .mars-facts-earth-after {
        left: -250px;
    }

    .mars-facts-mars-after {
        right: -250px;
    }

    div.active-fact-text strong, div.passive-fact-text strong {
        font-size: 36px;
    }

    div.oval-facts-active.oval-mars {
        left: -10px;
    }

    div.active-fact-text-mars {
        left: -150px;
        width: 110px;
        word-break: break-word;
    }

    div.oval-facts-passive.oval-mars {
        left: 65px;
    }

    div.passive-fact-text-mars {
        left: -45%;
    }

    div.oval-facts-active.oval-earth {
        right: -10px;
    }

    div.active-fact-text-earth {
        right: -150px;
        width: 110px;
        word-break: break-word;
    }

    div.oval-facts-passive.oval-earth {
        right: 65px;
    }

    div.passive-fact-text-earth {
        right: -45%;
    }
}

@media only screen and (max-width: 464px) {
    .mars-facts-section {
        background: url("../../assets/images/375/bg-facts.jpg") no-repeat top center/cover;
        height: 677px;
    }

    div.fact-main-text {
        top: 43%;
    }

    .mars-facts-earth {
        background: url("../../assets/images/375/earth.png") no-repeat top center/100%;
    }

    .mars-facts-mars {
        background: url("../../assets/images/375/mars.png") no-repeat right bottom/100%;
    }

    .mars-facts-planets-block {
        display: block;
        top: 0px;
        height: 100%;
    }

    .mars-facts-planet-block {
        width: 80%;
        left: 10%;
        right: 10%;
        height: 33.3333333%;
    }

    .mars-facts-planets {
        height: 150px;
    }

    .mars-facts-earth-after, .mars-facts-mars-after {
        width: 100%;
        height: 300px;
        padding: 0px;
        bottom: 0px;
    }

    .mars-facts-earth-after {
        left: 0px;
        top: -140px;
    }

    .mars-facts-mars-after {
        right: 0px;
        bottom: 0px;
    }

    div.passive-fact-text, div.oval-facts-passive {
        display: none;
    }

    div.oval-facts-active, div.active-fact-text {
        text-align: center;
    }

    div.active-fact-text {
        width: 100%;
    }

    div.oval-facts-active.oval-mars {
        left: 50%;
        top: -10px;
        bottom: 0px;
    }

    div.active-fact-text-mars {
        top: -71px;
        left: 0px;
    }

    div.oval-facts-active.oval-earth {
        right: 46%;
        top: 290px;
        bottom: -10px;
    }

    div.active-fact-text-earth {
        top: 321px;
        right: 0px;
    }

}
