.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 110;
}

.modal-main {
    position: fixed;
    background: white;
    height: auto;
    top: 60px;
    left: 50%;
    transform: translate(-50%,0%);
    max-width: 95%;
    max-height: calc(95% - 60px);
    overflow: auto;

}

.display-block {
    display: block;
}

.display-none {
    display: none;
}
.modal_close{
    position: absolute;
    right: 25px;
    top: 25px;
    width: 30px;
    height: 30px;
    display: block;
    background: url("modal_close.png") no-repeat center;
    cursor: pointer;

}