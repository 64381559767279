@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap");
:root {
  --card-width: 30vw;
  --card-height: 30vw;
  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
}


button:focus {
  outline: none;
  border: none;

}
.slider_wrap {
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.slider_wrap__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -5;
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
  overflow: hidden;
}
.slider_wrap__bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1;
  opacity: 0.8;
}
.slider_wrap__bg__image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--image-translate-offset, 0));
  width: 180%;
  height: 180%;
  transition: transform 1000ms ease, opacity 1000ms ease;
  overflow: hidden;
}
.slider_wrap__bg__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider_wrap__bg__image.current--image {
  opacity: 1;
  --image-translate-offset: 0;
}
.slider_wrap__bg__image.previous--image, .slider_wrap__bg__image.next--image {
  opacity: 0;
}
.slider_wrap__bg__image.previous--image {
  --image-translate-offset: -25%;
}
.slider_wrap__bg__image.next--image {
  --image-translate-offset: 25%;
}
.cardList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: auto;
}
.cardList__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: transparent;
}
.cardList__btn:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}

.cardList__btn:hover:before{
  opacity: 0.5;
  color: #fff;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
}
.cardList__btn.btn--left {
  left: -45px;
  background-image: url("../images/slider/left.png");
}
.cardList__btn.btn--right {
  right: -45px;
  background-image: url("../images/slider/right.png");
}
.cardList__btn .icon {
  width: 100%;
  height: 100%;
}
.cardList__btn .icon svg {
  width: 100%;
  height: 100%;
}
.cardList .cards__wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  perspective: 1000px;
}
.card {
  --card-translateY-offset: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--card-translateX-offset)) translateY(var(--card-translateY-offset)) rotateY(var(--card-rotation-offset)) scale(var(--card-scale-offset));
  display: inline-block;
  width: var(--card-width);
  height: var(--card-height);
  transition: transform var(--card-transition-duration) var(--card-transition-easing);
  user-select: none;
}
.card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: opacity var(--card-transition-duration) var(--card-transition-easing);
  opacity: calc(1 - var(--opacity));
}
.card__image {
  position: relative;
  width: 100%;
  height: 100%;
}
.card__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card.current--card {
  --current-card-rotation-offset: 0;
  --card-translateX-offset: 0;
  --card-rotation-offset: var(--current-card-rotation-offset);
  --card-scale-offset: 1.2;
  --opacity: 0.8;
  z-index: 50;
  background-color: #040415;
}
.card.previous--card {
  --card-translateX-offset: calc(-1 * var(--card-width) * 1.1);
  --card-rotation-offset: 25deg;
}
.card.next--card {
  --card-translateX-offset: calc(var(--card-width) * 1.1);
  --card-rotation-offset: -25deg;
}
.card.previous--card, .card.next--card {
  --card-scale-offset: 0.9;
  --opacity: 0.4;
}
.infoList {
  position: absolute;
  width: var(--card-width);
  height: var(--card-height);
  pointer-events: none;
}
.infoList .info__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.info {
  transform: translateZ(2rem);
  transition: transform var(--card-transition-duration) var(--card-transition-easing);
}
.info .text {
  position: relative;
  font-size: 20px;
  color: #fff;
  width: fit-content;
}
.info .description {
  --text-size-offset: 0.065;
  font-weight: 500;
}
.info.current--info {
  opacity: 1;
  display: block;
}
.info:not(.current--info){
  opacity: 0;
  display: none;
}
.support {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  display: flex;
}
.support a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.8rem;
  backface-visibility: hidden;
  transition: all 150ms ease;
}
.support a:hover {
  transform: scale(1.1);
}
.card:not(.current--card,.next--card,.previous--card){
  opacity: 0;
  transform: translateY(-50%);
}
.current--card,.next--card,.previous--card{
  opacity: 1;
  top: 50%;

}
.current--card .card__image:after {
  content: "";
  background: linear-gradient(179.93deg, rgba(0, 0, 0, 0) 0.06%, rgb(0 0 0 / 66%) 73.34%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 1280px) {
  .cardList {max-width: 1100px;}
  .cards__wrapper {max-width: 1100px;--card-width: 350px;}
  .cardList__btn.btn--left {
    left: -80px;
  }
  .cardList__btn.btn--right {
    right: -80px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --card-width: 300px;
    --card-height: 300px;
  }
  .cardList__btn.btn--left {
    left: 10px;
  }
  .cardList__btn.btn--right {
    right: 10px;
  }
  .cardList{
    width: 100%;

  }
  .cards__wrapper{
    min-width: 1024px;
    margin-left: calc( 50vw - 512px);
  }
  .card.previous--card{
    --card-translateX-offset: -325px;
  }
  .card.next--card{
    --card-translateX-offset: 325px;
  }
}
@media screen and (max-width: 500px){
  :root {
    --card-width: 220px;
    --card-height: 350px;
  }
  .card.previous--card{
    --card-translateX-offset: -240px;
  }
  .card.next--card{
    --card-translateX-offset: 240px;
  }
  .info .text{
    font-size: 18px;
  }
  .cardList__btn.btn--left {
    left: 5px;
  }
  .cardList__btn.btn--right {
    right: 5px;
  }
}