.camp_main,.city_main{
    background-color: #000000;
    padding: 1px;
}
.title_bar{
    font-size: 30px;
    background-color: #0BAAC629;
    color:#0BAAC6;
    padding: 15px 0px;
    margin-bottom: 17px;
    margin-top: 17px;
}
.title_bar_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title_bar_big{
    font-size: 55px;
}
.title_bar_small{
    max-width: 525px;
}
.camp_sections{
    display: flex;

}
.camp_section{
    width: 33.333%;
    color: #FFFFFF;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
}
.camp_section_music{
    background-image: url("images/music.png");
    padding-left: 0px;
}
.camp_section_lectures{
    background-image: url("images/lectures.png");
}
.camp_section_exhibitors{
    background-image: url("images/exhibitors.png");
}
.camp_section_title{
    font-size: 50px;
    margin-bottom: 40px;
}
.camp_group{
    padding-bottom: 20px;
}
.lent{
    width: 100%;
    height: 28.4vw;
    margin-top: 40px;
    margin-bottom: 40px;
    background-image: url("images/lent.png");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: flex-end;
}
.small_white{
    font-size: 15px;
    color: #FFFFFF;
}
.city_program_section{
    min-height: 567px;
    display: flex;
    align-items: center;
    background: url("images/city_program_bg.png") no-repeat center;
    background-size: cover;
    padding: 20px 0px 50px;
}
.u_img_wrap{
    height: 120px;
    display: flex;
    margin-bottom: 10px;
    align-items: flex-end;
}
.u_name{
    color: #0BAAC6;
    height: 70px;
    font-size: 20px;
}
.time_group{
    font-size: 25px;
    color: #FFFFFF;
    margin-top: 30px;
}
.time_group_speaker{
    margin-top: 8px;
    margin-bottom: 4px;
}
.time_group_title{
    font-size: 20px;
}
.city_program_col{
    margin-top: 50px;
}
.welcome_event{
    font-size: 30px;
    color: #ffffff;
    padding: 10px 0px;
}
.welcome_event_time{
    display: inline-block;
    margin-right: 80px;
}
.ripa_music{
    padding-right: 10px;
}
@media screen and (max-width: 1024px) {
    .title_bar_big{
        font-size: 45px;
    }
    .title_bar_small{
        font-size: 23px;
        flex-shrink: 0;
    }
    .camp_section_title{
        font-size: 37px;
    }
    .camp_group{
        font-size: 20px;
    }
    .city_program_col {
        width: 50%;
    }
}
@media screen and (max-width: 800px) {
    .title_bar_inner{
        flex-direction: column;
        align-items: flex-start;
    }
    .title_bar_small{
        margin-top: 10px;
    }
    .camp_sections{
        flex-direction: column;
    }
    .camp_section{
        width: 100%;
    }
}
@media screen and (max-width: 800px) {
    .city_program_col {
        width: 100%;
    }
}