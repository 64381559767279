.mars-awakens-block-text {
    position: absolute;
    top: 46px;
    left: 56px;
}

h1.mars-awakens-h1 {
    margin: 0;
}

p.mars-awakens-p {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
}

div.mars-awakens-block {
    position:relative;
    margin:0 auto;
    height:100vh;
}

.mars-awakens-slider {
    background: no-repeat top center/cover;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    div.mars-awakens-block {
        height:660px;
    }
    p.mars-awakens-p {
        font-size: 26px;
    }
    .mars-awakens-block-text {
        position: absolute;
        top: 50px;
        left: 25px;
    }
}

@media only screen and (max-width: 464px) {
    div.mars-awakens-block {
        height:560px;
    }
    p.mars-awakens-p {
        font-size: 19px;
    }
    .mars-awakens-block-text {
        position: absolute;
        top: 60px;
        left: 22px;
    }
}
