.mars3-section {
    background: url("../../assets/images/bg-mars-3.jpg") no-repeat top/cover;
}

.mars3-block-text {
    bottom: 51px;
    left: 55px;
    width: 596px;
    position: absolute;
}

p.mars3-p {
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
    .mars3-section {
        background: url("../../assets/images/768/mars-3.jpg") no-repeat top/cover;
    }
    .mars3-block-text {
        bottom: 50px;
        left: 25px;
        width: 80%
    }
    p.mars3-p {
        font-size: 16px;
    }
    .mars3-text {
        padding-top: 10px;
    }
    h1.mars3-title {
        font-size: 40px;
    }
    div.mars3-block {
        height: 660px;
    }
}

@media only screen and (max-width: 375px) {
    .mars3-section {
        background: url("../../assets/images/375/mars-3.jpg") no-repeat top/cover;
    }
    .mars3-block-text {
        left: 22px;
        width: 80%
    }
    p.mars3-p {
        font-size: 14px;
    }
    h1.mars3-title {
        font-size: 30px;
    }
    div.mars3-block {
        height: 560px;
    }
}