.settling-section {
    background: url("../../assets/images/bg-settling.jpg") no-repeat top/cover;
    height: 100vh;
}

.settling-block-text {
    position: absolute;
    left: 50px;
    top: 51px;

}

.settling-text p {
    font-size: 18px;
    margin-bottom: 20px;
    width: 60%;
}

@media only screen and (max-width: 768px) {
    .settling-section {
        height: 660px;
    }

    div.settling-block {
        background: url("../../assets/images/768/bg-settling.jpg") no-repeat top/cover;
    }

    .settling-text {
        padding-top: 27px;
    }

    .settling-text p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .settling-block-text {
        left: 24px;
        top: 71px;

    }
}

@media only screen and (max-width: 464px) {
    .settling-section {
        height: 560px;
    }

    div.settling-block {
        background: url("../../assets/images/375/bg-settling.jpg") no-repeat top/cover;
    }

    .settling-text {
        padding-top: 15px;
    }

    .settling-text p {
        font-size: 13px;
        margin-bottom: 3px;
        width: 90%;
    }

    .settling-block-text {
        left: 21px;
        top: 56px;
    }
}