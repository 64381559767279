#menu-header{
    background-color: rgba(0, 0, 0, 0.3);
}
.main-banner-section {
    background: url("../../assets/images/earth.avif") no-repeat center;
    background-size: cover;

}

div.main-banner-container {
    height: 100vh;
    overflow: hidden;
}

.main_banner_video {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    /*height: 100%;*/
    filter: opacity(78%);
    position: relative;
    z-index: 0;
}

.mars-3-img, .mariner-9-img {
    display: none;
}

.main-banner-text {
    width: 470px;
    position: absolute;
    top: 221px;
    left: 56px;
}

.starmus-title {
    margin-bottom: 20px;
}

div.starmus-about {
    width: 100%;
    font-size: 30px;
    line-height: 44px;
    margin-bottom: 20px;
}

span.starmus-about-highlighted {
    color: #ff8200;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    div.main-banner-container, div.main-banner {
        height: 646px;
    }

    .main-banner-text {
        left: 22px;
    }

    div.starmus-about {
        font-size: 26px;
        line-height: 26px;
    }

    .mariner-9-img {
        display: none;
    }
}

@media only screen and (max-width: 768px) {

    .main_banner_video {
        display: none;
    }
    .mars-3-img {
        width: 200px;
        top: 310px;
    }

    .mars-3-img, .mariner-9-img {
        display: block;
        position: absolute;
        opacity: 0.9;
    }

    .mars-3-img {
        right: -43px;
        top: 300px;
        width: 420px;
        animation: spin 10s linear infinite;
    }

    .mariner-9-img {
        top: 82px;
        right: 762px;
        width: 250px;
        animation: spin- 10s linear infinite;
    }
}

@media only screen and (max-width: 464px) {

    div.main-banner-container, div.main-banner {
        height: 643px;
    }

    div.starmus-about {
        font-size: 18px;
        margin-bottom: 3px;
    }

    .mars-3-img {
        width: 170px;
    }
}