div.races-to-mars-block {
    background: url("../../assets/images/bg-race.jpg") center/cover no-repeat;
    height: 100vh;
}
.carousel-block {
    bottom: 53px;
    position: absolute;
    width: 100%;
}
.race-to-marc-component p {
    margin-bottom: 20px;
}

.race-to-marc-component {
    list-style: none;
    padding-left: 56px;
}

.carousel-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 56px;
}

.mars-awakens-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
}

.race-to-mars-text {
    padding-left: 56px;
}

p.race-name {
    font-size: 30px;
    font-weight: bold;
}

p.race-attribute {
    font-size: 16px;
}

.empty-space {
    width: 10px;
}

.race-attribute-container {
    display: flex;
}

@media only screen and (max-width: 768px) {
    div.races-to-mars-block {
        background: url("../../assets/images/768/bg-race.jpg") center/cover no-repeat;
        height: 660px;
    }
    p.race-name {
        font-size: 18px;
    }
    p.race-attribute {
        font-size: 14px;
    }
    .race-to-mars-text {
        padding-left: 25px;
    }
    .race-to-marc-component {
        padding-left: 25px;
    }
    .carousel-buttons {
        padding-right: 26px;
    }
}

@media only screen and (max-width: 464px) {
    div.races-to-mars-block {
        background: url("../../assets/images/375/bg-race.jpg") center/cover no-repeat;
        height: 560px;
    }
    p.race-name {
        font-size: 16px;
    }
    .race-to-mars-text {
        padding-left: 18px;
    }
    .race-to-marc-component {
        padding-left: 18px;
    }
    .race-to-marc-component-item {
        margin-bottom: -10px;
    }
    .carousel-buttons {
        display: none;
    }
}