div.mars-volkano-block {
    height: 100vh;
}

div.mars-volkano-slide {
    height: 100vh;
}

.mars-volkano-text {
    top: 71px;
    left: 56px;
    width: 515px;
    position: absolute;
}

@media only screen and (max-width: 768px) {
    div.mars-volkano-block {
        height: 661px;
    }

    div.mars-volkano-slide {
        height: 661px;
    }

    .mars-volkano-text {
        top: 51px;
        left: 25px;
        width: 80%;
    }
}

@media only screen and (max-width: 464px) {
    div.mars-volkano-slide {
        height: 540px;
    }

    div.mars-volkano-block {
        height: 540px;
    }

    p.mars-volkano-text {
        top: 52px;
        left: 16px;
        width: 90%;
        font-size: 18px;
    }
}