:root{
    --ripa_padding:50px;
}
.programContent{
    font-family: Bank_Gothic;
}
.programMain{
    position: relative;
}
.header{
    background: url("ProgramNew/images/program_header_bg.png") no-repeat center;
    height: 35vw;
    background-size: cover;
    transition: all 0.3s;
    position: relative;
}
.eset_link{
    position: absolute;
    font-size: 0px;
    display: inline-block;
    width: 15vw;
    height: 2vw;
    bottom: 33%;
    z-index: 5;
    right: 4.5%;
}
.section_2{
    background-color: #CCCCCC69;
    padding-bottom: 46px;
    position: relative;
}

.abs_ticket{
    display: flex;
    justify-content: flex-end;
    position: sticky;
    z-index: 5;
    top: 60px;
}
.buyTicket{
    border-radius: 20px 0px 0px 20px;
    background-color: #F47A35;
    color: #FFFFFF;
    font-size: 40px;
    text-transform: uppercase;
    width: 360px;
    padding: 10px ;
    text-align: center;
    z-index: 2;
    top: 30px;
    position: absolute;
}
.buyTicketInner{
    top:117px;
}
.abs_ticket_inner{
    top: -23px;
}
.section_2_title{
    padding-top: 28px;
    font-size: 60px;
    color: #0BAAC6;
    margin-bottom: 51px;
}
.dateList{
    display: flex;
    flex-wrap: wrap;
}
.dateListItem{
    width: 33%;
    padding-right: 30px;
    cursor: pointer;
    margin-bottom: 46px;
}
.dateListText{
    margin-top: -30px;
    margin-left: 90px;
}
.dateListDay{
    letter-spacing: -50px;
    color: #A1A1A14D;
    font-size: 200px;
    text-indent: -40px;
    line-height: 120px;
}
.dateListLabel{
    color: #F47A35;
    font-size: 40px;
}
.dateListDesc{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    max-width: 280px;
    text-transform: lowercase;
}
.dateListMore{
    text-align: right;
    font-size: 15px;
    border-top: 3px solid;
    width: 120px;
    color: #F47A35;
}
.section_3{
    background: url(ProgramNew/images/p_info_bg.jpeg) black no-repeat left center;
    background-size: cover;
    padding: 64px 0px 32px;
}
.section_3_title{
    font-size: 50px;
    color: #FFFFFF;
    text-align: right;
    margin-bottom: 40px;
}
.section_3_left{
    color: #FFFFFF;
    width: 57%;
    padding-right: 20px;
    font-size: 25px;
    line-height: 30px;
}
.section_3_p{
    margin-bottom: 20px;
}
.section_3_right{
    background: url("ProgramNew/images/section_3_r_bg.png") no-repeat bottom 80px center;
    width: 43%;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 30px;
    text-align: right;
}
.section_3_left_bottom{
    margin-top: 20px;
    margin-bottom: 20px;
}
.pItemInner{
    position: relative;
}
.pItemTitle{
    background-color: #0BAAC6;
    font-size: 40px;
    color: #FFFFFF;
    padding: 25px 0px;
}
.pItemTitleInner{
    display: flex;
    align-items: center;
}
.pItemTitleLeft{
    display: flex;
    align-items: center;
    gap: 10px;
}
.pItemTitleClose{
    width: 50px;
    height: 50px;
    background: url("ProgramNew/images/close_btn.png") no-repeat center;
    cursor: pointer;
    margin-left: auto;
}
.programInfo{
    background: url(ProgramNew/images/p_info_bg.jpeg) black no-repeat left center;
    background-size: cover;
    padding: 17px 0px 32px;
}
.programInfoTitle{
    color: #FFFFFF;
    font-size: 50px;
}
.programDayBlue{
    background-color: #0BAAC6;
    font-size: 40px;
    border-radius: 0px 20px 20px 0px;
    padding-left: var(--ripa_padding);
    padding-right: 20px;
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    display: inline-block;
}
.pItemList{
    column-count: 2;
    column-gap: 50px;
    margin-top: 50px;

}
.m_top_50{
    margin-top: 50px;
}
.pItemListItem{
    margin-bottom: 20px;
}
.pItemGroupTitle{
    font-size: 40px;
    color: #F47A35;
}
.pItemGroupRow{
    font-size: 30px;
    color: #FFFFFF;
    padding-left: 70px;
    display: block;
}
.pItemExtra{
    color: #FFFFFF;
    font-size: 25px;
    text-align: right;
    margin-top: 20px;
}
.pItemExtraRow1{
    font-size: 30px;
}
.medal_section_1{
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 80px 0px;
}
.medal_section_1_text{
    font-size: 20px;
    color: #FFFFFF;
    max-width: 312px;
    margin-bottom: 20px;
    margin-left: 50px;
}
.medal_section_2{
    padding-bottom: 80px;
}
.abs_bg{
    width: 45%;
    position: absolute;
    bottom: 0;
    right: 0;
}
.ripa_container{
    width: 100%;
    max-width: 1440px;
    padding-left: var(--ripa_padding);
    padding-right: var(--ripa_padding);
    margin-left: auto;
    margin-right: auto;
}
.welcome_bg{
    background-color: #000000;
    padding: 70px 0px 20px;
    position: relative;
}

.welcome_inner{
    position: relative;
    z-index: 2;
    padding-top: 60px;
}
.welcome_content{

    background-image: url("ProgramNew/images/welcome_bg.png");
    background-size: cover;
    background-position: center;
    height: 50vw;
}
.welcome_content_inner{
    display: flex;
    align-items: end;
    flex-direction: column;
    height: 100%;
    text-align: right;
}
.welcome_text{
    width: 50%;
    font-size: 40px;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
}
.welcome_text2{
    color: #F47A35;
    max-width: 40%;
    font-size: 25px;
}
.starPartyBg{
    background-image: url("ProgramNew/images/star_party_bg.jpg");
    background-size: cover;
    background-position: center;
    padding: 1px 0px;
    position: relative;
    min-height: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.starPartyBg:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: #00000063;
}
.starPartyInner{
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.starPartyProgram{
    font-size: 30px;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.35);
    margin-bottom: 30px;
}
.starPartyGroup{
    margin: 20px;
}
.starPartyText{
    font-size: 35px;
    margin-bottom: 23px;
    flex-shrink: 0;
    margin-left: 30px;
}
.starPartyRow2{
    font-size: 35px;
    text-align: right;
}

/*partners*/
.white_section{
    background-color: #FFFFFF;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #000000;
}
.section_title{
    font-size: 50px;
    margin-bottom: 20px;
}
.m_t_1{
    margin-top: 10px;
}
.m_t_2{
    margin-top: 20px;
}
.break_column{
    break-after: column;
}
.color_orange{
    color: #F47A35;
}
.color_white{
    color: #FFFFFF;
}
.color_grey{
    color: #FFFFFF5C;

}
.color_blue{
    color: #0BAAC6;
}
@media screen and (max-width: 1200px){
    .section_3_right{
        width: 35%;
    }
    .section_3_left{
        width: 75%;
    }
    .section_2_title{
        font-size: 55px;
    }
    .buyTicket{
        width: auto;
        padding-right: 20px;
        font-size: 35px;
    }
    .programDayBlue{
        font-size: 35px;
    }
    .section_3_inner{
        padding: 0px 50px;
    }
    .medalProgramInfo{
        background-position: left -150px center;
    }
}
@media screen and (max-width: 1024px){
    .section_3_inner{
        flex-wrap: wrap;
    }
    .section_3_right{
        width: 100%;
        background-image: none;
    }
    .section_3_left{
        width: 100%;
    }
    .section_2{
        padding-top: 85px;
    }
    .pItemGroupTitle{
        font-size: 30px;
    }
    .pItemGroupRow{
        font-size: 25px;
        padding-left: 25px;
    }
    .buyTicket{
        top: 40px;
    }
    .buyTicketInner{
        top: 117px;
    }
    .section_3{
        background-position: left -150px center;
    }


}
@media screen and (max-width: 800px){

    .dateListItem{
        width: 50%;
    }
    .dateListText{
        margin-left: 60px;
    }
    .pItemList{
        column-count: 1;
    }
    .welcome_text{
        font-size: 30px;
        width: 60%;
    }
    .welcome_text2{
        font-size: 18px;
        width: 50%;
    }
    .break_column{
        break-after: auto;
    }
    .section_3_title{
        font-size: 40px;
    }
    .section_3{
        background-position: left -250px center;
    }
    .medal_section_1{
        flex-direction: column;
        align-items: center;
    }
    .medal_section_1_text{
        margin: 30px auto;
    }
    .medal_section_2{
        margin: auto;
    }
    .abs_bg{
        display: none;
    }
    .pItemTitleLeft{
        flex-direction: column;
        font-size: 28px;
        line-height: 18px;
        align-items: baseline;
    }
    .pItemTitleDivider{
        display: none;
    }
    .programDayBlue{
        font-size: 28px;
    }
    .buyTicket{
        font-size: 28px;
    }
    .section_title{
        font-size: 40px;
    }
    .welcome_img{
        width: 45%;
    }
    .starPartyInner{
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 600px){
    :root{
        --ripa_padding: 30px;
    }
    .dateListItem{
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .section_2_title{
        font-size: 45px;
    }
    .starPartyText{
        font-size: 25px;
    }
    .pItemGroupRow{
        padding-left: 40px;
    }
    .section_3_inner{
        padding: 0px 20px;
    }
    .section_title{
        font-size: 33px;
    }
    .welcome_content{
        background:  url("ProgramNew/images/welcome_bg_small.png");;
        padding-top: 150vw;
        height: auto;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        padding-bottom: 30px;
    }
    .welcome_text{
        font-size: 22px;
        min-width: 100%;
    }
    .welcome_text2{
        margin-top: 20px;
        min-width: 100%;
        font-size: 16px;
    }
    .welcome_contet{
        flex-direction: column;
        gap: 40px;
    }
}
