.location-block {
    margin: 15px 0px;
}

.location-block-wrap {
    display: flex;
    justify-content: flex-start;
}

.location-block-text, .location-right-content {
    padding: 56px;
}
.location-block-image {
    width: 30%;
    flex-shrink: 0;
}

.location-text-wrapper, h1.location-h1 {
    margin-bottom: 26px;
}

p.location-p, p.getting-armenia-p {
    font-size: 16px;
}

.getting-armenia-block {
    background: url("../../assets/images/bg-getting-armena.jpg") no-repeat top center/cover;
    height: 620px;
    text-align: center;
    display: flex;
    align-items: center;
}

.getting-armenia-block div {
    width: 50%;
    margin: auto;
}

@media only screen and (max-width: 1024px) {
    .location-block-text, .location-right-content {
        padding: 31px;
    }

    .location-block-wrap {
        display: block;
    }

    .location-block-image {
        text-align: center;
    }
}


@media only screen and (max-width: 464px) {
    .getting-armenia-block {
        background: url("../../assets/images/375/bg-getting-armena.jpg") no-repeat top center/cover;
    }

    .location-block-image .location-img {
        width: 100%;
    }

    .location-block-text, .location-right-content {
        padding: 21px;
    }
}
