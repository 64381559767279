div.mystery-of-red-planet-block {
    background: url("../../assets/images/bg-mystery.jpg") no-repeat center/cover;
    height: 100vh;
}

div.mystery-of-red-planet-block-text {
    top: 62px;
    position: relative;
    padding-left: 24px;
    padding-right: 23px;
}

h1.mystery-of-red-planet-title {
    text-align: center;
}

@media only screen and (max-width: 768px) {
    div.mystery-of-red-planet-block {
        height: 658px;
        background: url("../../assets/images/768/bg-mystery.jpg") no-repeat center/cover;
    }
    div.mystery-of-red-planet-block-text {
        top: 39px;
    }
}

@media only screen and (max-width: 464px) {
    div.mystery-of-red-planet-block {
        background: url("../../assets/images/375/bg-mystery.jpg") no-repeat center/cover;
    }
}