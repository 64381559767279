.arrows-button-oval-container {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
}

.arrows-button-oval-container:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 1);
}

.arrows-button-image-container {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.arrows-button {
    position: absolute;
    width: 16px;
    height: 12px;
}

.left-arrow {
    background: url("../../assets/icons/left_icon.png") no-repeat top/cover;
}

.right-arrow {
    background: url("../../assets/icons/right_icon.png") no-repeat top/cover;
}

@media only screen and (max-width: 375px) {
    .arrows-button-oval-container {
        width: 30px;
        height: 30px;
    }
    .arrows-button {
        width: 11px;
        height: 7px;
    }
}