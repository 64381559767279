.scroll-item-container {
    position: absolute;
    bottom: 13px;
    align-items: center;
    width: 100%;
    text-align: center;
}

.scroll-item {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    opacity: 0.5;
}

.scroll-item:hover {
    cursor: pointer;
    opacity: 1;
}

.scroll-item img {
    width: 13px;
}

.scroll-down {
    padding-top: 1px;
    font-size: 11px;
    color: #ffffff;
    text-align: center;
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 464px) {
    .scroll-item-container {
        display: none;
    }
}

