@import "../assets/fonts/roboto/style.css";
@font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/FormaDJRVariable/FormaDJRText-Bold-Testing.ttf') format('truetype');
    font-display: swap;
    font-weight: bold;
}
@font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/FormaDJRVariable/FormaDJRText-Light-Testing.ttf') format('truetype');
    font-display: swap;
    font-weight: 300;
}
@font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/FormaDJRVariable/FormaDJRText-Regular-Testing.ttf') format('truetype');
    font-display: swap;
    font-weight: 400;
}
@font-face {
    font-family: FormaDJRVariable;
    src: url('../assets/fonts/FormaDJRVariable/FormaDJRText-Bold-Testing.ttf') format('truetype');
    font-display: swap;
    font-weight: bold;
}
@font-face {
    font-family: FormaDJRVariable;
    src: url('../assets/fonts/FormaDJRVariable/FormaDJRText-Light-Testing.ttf') format('truetype');
    font-display: swap;
    font-weight: 300;
}
@font-face {
    font-family: FormaDJRVariable;
    src: url('../assets/fonts/FormaDJRVariable/FormaDJRText-Regular-Testing.ttf') format('truetype');
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: Veneer;
    src: url('../assets/fonts/Veneer/Veneer.ttf');
    font-display: swap;
}

@font-face {
    font-family: Bank_Gothic;
    src: url('../assets/fonts/BankGothic/Bank_Gothic.ttf');
    font-display: swap;
}

@font-face {
    font-family: ApexMk2;
    src: url('../assets/fonts/ApexMk2/ApexMk2-Regular.otf');
    font-display: swap;
    font-weight: normal;
}
@font-face {
    font-family: ApexMk2;
    src: url('../assets/fonts/ApexMk2/ApexMk2-BoldExtended.otf');
    font-display: swap;
    font-weight: bold;
}

html, body {
    /*height: 100%;*/
    font-family: Montserrat;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body .style_2024{
    font-family: FormaDJRVariable;
}
.figure_position{
    color: #ff8200;
}

.s7_button{
    padding: 0px 23px;
    height: 50px;
    background: #0085FF;
    border-radius: 6px;
    line-height: 50px;
    font-family: FormaDJRVariable;
    font-weight: 400;
    font-size: 18px;
    border: none;
    outline: none;
    color: #ffffff;
}
.s7_form_group{
    margin-bottom: 20px;
}
.s7_form .form_error{
    font-size: 14px;
    color: #cc0c0c;
}
.s7_form label{
    font-size: 16px;
}
.s7_form input{
    width: 100%;
    height: 50px;
    line-height: 48px;
    border: 1px solid #E5E5E5;
    font-size: 18px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, ul, li {
    margin: 0px;
    padding: 0px;
}

ul, li {
    list-style-type: none;
}

a, a:link, a:visited, a:hover, a:active {
    text-decoration: none;
}

.hidden, .hide-component {
    display: none;
}

.fade-in {
    opacity: 1;
    transition: opacity 1s;
}

.fade-out {
    opacity: 0;
}

.container {
    position: relative;
}

div.starmus-block {
    height: 100vh;
    position: relative;
}

h1.starmus-h1, h1.starmus-h2, h1.starmus-h4, h1.starmus-black-h1, h1.starmus-black-h2, h1.starmus-black-h4 {
    font-family: Veneer;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

h1.starmus-h1, h1.starmus-h2, h1.starmus-h4 {
    color: #ffffff;
}

h1.starmus-black-h1, h1.starmus-black-h1-h2, h1.starmus-black-h4 {
    color: #000000;
}

h1.starmus-h1, h1.starmus-black-h1 {
    font-size: 68px;
}
h1.starmus-h1.new_banner_h1{
    margin-bottom: 20px;
    font-size: 75px;
    color: #ff8200;
}
h1.starmus-h2, h1.starmus-black-h2 {
    font-size: 60px;
}

h1.starmus-h4, h1.starmus-black-h4 {
    font-size: 45px;
}

p.starmus-p, div.starmus-p, p.starmus-black-p, div.starmus-black-p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    margin: 0px;
    text-align: justify;
}

p.starmus-p, div.starmus-p {
    color: #ffffff;
}

p.starmus-black-p, div.starmus-black-p {
    color: #000000;
}

.wrapper {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 2px;
    -webkit-perspective: 2px;
    -moz-perspective: 2px;
}

.section {
    /* Needed for children to be absolutely positioned relative to the parent. */
    position: relative;
    /* The height of the container. Must be set, but it doesn't really matter what the value is. */
    height: 100vh;
}

.parallax::after {
    /* Display and position the pseudo-element */
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* Move the pseudo-element back away from the camera,
     * then scale it back up to fill the viewport.
     * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
    transform: translateZ(-1px) scale(1.5);
    -moz-transform: translateZ(-1px) scale(1.5);
    -webkit-transform: translateZ(-1px) scale(1.5);
    /*-webkit-transform: translateZ(-1px) scale(1.5);*/
    /* Force the background image to fill the whole element. */
    background-size: 100%;
    /* Keep the image from overlapping sibling elements. */
    z-index: -1;
}

.oval {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
}

.oval::after {
    content: "";
    display: block;
    background-color: #ffffff;
    border-radius: 50%;
}


.cover-parent {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.scroll-up-button {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.loading-component {
    width: 100%;
    height: 100vh;
    align-items: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(88, 45, 0, 1) 100%, rgba(255, 130, 0, 1) 100%);
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 999;
}

/*2025*/
.new_menu{
    font-family: "Bank_Gothic";
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(8deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-8deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes spin- {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-8deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(8deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes blinkingBackground {
    50% {
        opacity: 0;
    }
}

.starmus-date-location {
    font-size: 20px;
    text-transform: uppercase;
}

.starmus-breaking-news {
    position: absolute;
    top: 124px;
    left: 56px;
    border: 1px solid #ff8200;
    border-radius: 25px;
}

.starmus-breaking-news a {
    display: block;
    color: #ff8200;
    font-weight: bold;
    font-size: 24px;
    padding: 10px 25px;
    animation: blinkingBackground 2s linear infinite;
}

@media only screen and (max-width: 1024px) {

    div.starmus-date-location {
        font-size: 22px;
    }

    .section {
        height: auto;
    }

    .wrapper {
        perspective: none;
    }

    .parallax::after {
        transform: none;
    }
}

@media only screen and (max-width: 768px) {
    .starmus-breaking-news {
        top: 132px;
    }

    h1.starmus-h1, h1.starmus-h2, h1.starmus-black-h1, h1.starmus-black-h2 {
        font-size: 40px;
    }

    h1.starmus-h4, h1.starmus-black-h4 {
        font-size: 30px;
    }

    p.starmus-p, div.starmus-p, p.starmus-black-p, div.starmus-black-p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 464px) {
    .starmus-breaking-news {
        top: 102px;
    }

    h1.starmus-h1, h1.starmus-h2, h1.starmus-black-h1, h1.starmus-black-h {
        font-size: 30px;
    }

    h1.starmus-h4, h1.starmus-black-h4 {
        font-size: 22px;
    }

    p.starmus-p, div.starmus-p, p.starmus-black-p, div.starmus-black-p {
        font-size: 14px;
    }

    .section {
        height: auto;
    }

    .wrapper {
        perspective: none;
    }

    .parallax::after {
        transform: none;
    }

    div.starmus-date-location {
        font-size: 20px;
    }
}